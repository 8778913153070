import { graphql } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";

import FlexibleContent from "~/components/FlexibleContent";
import { WpPage_Page_FlexibleContent } from "~/_generated/types";

import Seo from 'gatsby-plugin-wpgraphql-seo';
import { CaseStudyMasthead } from '../components/flexible/mastheads/CaseStudyMasthead';

export default function categoryPage({
  data: { wpPage, wp, wpCategory },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {


  return (
    <Layout wp={wp}>
      <Seo post={wpCategory} title={wpCategory.name} />
      <FlexibleContent content={wpPage?.page?.flexibleContent} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Category($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
    wpCategory(id: { eq: $id }) {
      ...GeneratedWpCategory
    }
  }
`;
